// Адрес API
export const russpassApiUrl = process.env.REACT_APP_RUSSPASS_API_URL || "";

/**
 * Роуты сервисов для работы с api partner
 */
export const partnerApiServices = {
    billing: "/billing",
    services: "/services",
    servicesV2: "/v2/services",
    facilities: "/facilities",
    facilitiesV2: "/v2/facilities",
    hotels: "/hotels",
    refbook: "/refbook",
    profileSettings: "/profileSettings",
    excursions: "/excursions",
    excursionsV2: "/v2/excursions",
    dictionaryCms: "/dictionary/cms",
    dictionary: "/dictionary",
    crm: "/crm",
    documents: "/documents",
    files: "/files",
    orders: "/orders",
    tours: "/tours",
    integration: "/integration",
    applications: "/applications",
    partner: "/partner",
    restaurants: "/restaurants",
    specialOffers: "/hotels-special-offers/partner/specialOffers",
    mobilePhoneVerification: "/mobilePhoneVerification",
    ordersExport: "/orders/export",
};

const virtualStage = process.env.REACT_APP_VIRTUAL_STAGE || "";
/**
 * Роуты сервисов для работы с api russpass
 */
export const russpassApiServices = {
    attach: "/attach",
    billing: "/billingapi",
    emailNotify: "/notification/notify/email",
    jirasd: "/jirasd",
    rating: "/rating/partner",
    partnership: `/partnership${virtualStage}`,
    feedbackFiles: "/feedback/files",
    feedback: "/feedback/partner",
    dialogues: "/feedback/dialogues",
    notificator: "/notificator/lkp",
    rentHouses: "/renthouses-b2b",
    rentHousesSupport: "/renthouses-support",
    sso: "/sso",
    draft: "/draft",
    cmsApi: "/cmsapi",
    restaurantTypes: "/restaurantTypes",
    restaurantCharacteristic: "/restaurantCharacteristic",
};
